import { Injectable } from '@angular/core';
import { Form } from '../models/Form';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  form: Form;

  constructor() {
    this.form = new Form();
  }
}

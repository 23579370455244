export interface ProfitDetailsInterface {
    msrp: number,

    counterRetailPrice: number,

    costPrice: number,
    bookingDiscount: number,
    earlyDeliveryDiscount: number,
    contigoldDiscount: number,    // Contigold program
    acceletorDiscount: number,    // Contigold program
    contigoldVolumeDiscount: number,    // Contigold program
    dtTireVolumeDiscount: number,
    anunualIncentive: number,
    totalCost: number,

    profitPerTire: number,
    profitPerSet: number,
}

export class ProfitDetails implements ProfitDetailsInterface {
    msrp: number;

    counterRetailPrice: number;

    costPrice: number;
    bookingDiscount: number;
    earlyDeliveryDiscount: number;
    contigoldDiscount: number;
    acceletorDiscount: number;
    contigoldVolumeDiscount: number;
    dtTireVolumeDiscount: number;
    anunualIncentive: number;
    totalCost: number;

    profitPerTire: number;
    profitPerSet: number;

    constructor() {
        this.msrp = 0;

        this.counterRetailPrice = 0;

        this.costPrice = 0;
        this.bookingDiscount = 0;
        this.earlyDeliveryDiscount = 0;
        this.contigoldDiscount = 0;
        this.acceletorDiscount = 0;
        this.contigoldVolumeDiscount = 0;
        this.dtTireVolumeDiscount = 0;
        this.anunualIncentive = 0;
        this.totalCost = 0;

        this.profitPerTire = 0;
        this.profitPerSet = 0;
    }
}
<div class="card">
    <div class="card-body container">
        <form>
            <div class="form-floating mb-3">
                <input type="password" class="form-control" id="passwordInput" placeholder="Password"
                    [(ngModel)]="appPassword" [ngModelOptions]="{standalone: true}" required>
                <label for="clientNameInput">{{ "Login.PasswordLabel" | translate }}</label>
            </div>

            <div *ngIf="!isValid" class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>{{ "Login.LoginValidationMessage" | translate }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
        </form>
    </div>

    <div class="card-footer">
        <button type="button" class="btn btn-primary float-end" (click)="validatePassword()">
            {{ "Login.ButtonLabel" | translate }}
        </button>
    </div>
</div>
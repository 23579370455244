import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormService } from '../../services/form.service';
import { TireService } from '../../services/tire.service';
import { LoginService } from 'src/app/services/login.service';

import { Form } from '../../models/Form';
import { Tire } from '../../models/Tire'

@Component({
  selector: 'form-component',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  form: Form;
  tires: Array<Tire>;

  selectedTire: Tire;
  isStudded = 0;
  counterRetailPriceValue?: number | null;
  costValue?: number | null;
  bookingDiscountValue = 0;
  earlyDeliveryDiscountValue = 0;
  contigoldVolumeBonusValue = 0;
  dtTireVolumeBonusDollarValue = 0;
  dtTireVolumeBonusPercentageValue?: number | null;
  annualIncentiveValue = 0;

  isRegularRadioButtonDisabled = false;
  isStuddedRadioButtonDisabled = false;

  isAuthorized: boolean;

  constructor(private router: Router,
    private formService: FormService,
    private tireService: TireService,
    private loginService: LoginService
  ) {
    this.form = this.formService.form
    this.tires = this.tireService.tires;
    this.selectedTire = this.tires[0];
    this.isAuthorized = this.loginService.isAuthorized;
    //this.isAuthorized = true;
  }

  ngOnInit() {
    if (this.isAuthorized == false) {
      this.router.navigate([`/login`]);
    }
  }

  public tireSizeChanged(): void {
    if (this.selectedTire.id != 0) {
      this.form.tire = this.selectedTire;
      
      // Pas nécessaire si toutes les grandeurs ont des modèles studded et non-studded
      // this.validateTire();

      if (this.isStudded == 1) {
        this.form.tire.isStudded = true;
        this.form.profitDetails.msrp = this.form.tire.studdedPrice;
      } else {
        this.form.tire.isStudded = false;
        this.form.profitDetails.msrp = this.form.tire.regularPrice;
      }

      this.counterRetailPriceChanged();
    }
  }

  public counterRetailPriceChanged(): void {
    if (this.counterRetailPriceValue && this.counterRetailPriceValue > 0) {
      var counterRetailPricePourcentage = this.counterRetailPriceValue / 100;
      this.form.profitDetails.counterRetailPrice = this.form.profitDetails.msrp * (1 - counterRetailPricePourcentage);

      this.calculateProfit();
    }
  }

  public costPriceChanged(): void {
    if (this.costValue && this.costValue > 0) {
      var costPercentage = this.costValue / 100;
      this.form.profitDetails.costPrice = this.form.profitDetails.msrp * (1 - costPercentage);

      this.calculateProfit();
    }
  }

  public bookingDiscountChanged(): void {
    if (this.bookingDiscountValue != 0) {
      var bookingPercentage = this.bookingDiscountValue / 100;
      this.form.profitDetails.bookingDiscount = this.form.profitDetails.costPrice * bookingPercentage;

      this.calculateProfit();
    }
  }

  public earlyDeliveryDiscountChanged(): void {
    if (this.earlyDeliveryDiscountValue != 0) {
      this.form.profitDetails.earlyDeliveryDiscount = this.earlyDeliveryDiscountValue;

      this.calculateProfit();
    }
  }

  public contigoldVolumeBonusChanged(): void {
    if (this.contigoldVolumeBonusValue != 0) {
      this.form.profitDetails.contigoldVolumeDiscount = this.contigoldVolumeBonusValue;

      this.calculateProfit();
    }
  }

  public dtTireVolumeBonusDollarChanged(): void {
    if (this.dtTireVolumeBonusDollarValue != 0) {
      this.form.profitDetails.dtTireVolumeDiscount = this.dtTireVolumeBonusDollarValue;

      this.calculateProfit();
    }
  }

  public dtTireVolumeBonusPercentageChanged(): void {
    if (this.dtTireVolumeBonusPercentageValue && this.dtTireVolumeBonusPercentageValue > 0) {
      var dtVolumeBonusPercentage = this.dtTireVolumeBonusPercentageValue / 100;
      this.form.profitDetails.dtTireVolumeDiscount = (this.form.profitDetails.costPrice - this.form.profitDetails.bookingDiscount) * dtVolumeBonusPercentage;

      this.calculateProfit();
    }
  }

  public annualIncentiveChanged(): void {
    if (this.annualIncentiveValue != 0) {
      this.form.profitDetails.anunualIncentive = this.annualIncentiveValue;

      this.calculateProfit();
    }
  }

  private calculateProfit(): void {
    this.form.profitDetails.totalCost = this.form.profitDetails.costPrice
      - this.form.profitDetails.bookingDiscount
      - this.form.profitDetails.earlyDeliveryDiscount
      - this.form.profitDetails.contigoldDiscount
      - this.form.profitDetails.acceletorDiscount
      - this.form.profitDetails.contigoldVolumeDiscount
      - this.form.profitDetails.dtTireVolumeDiscount
      - this.form.profitDetails.anunualIncentive;

    this.form.profitDetails.profitPerTire = this.form.profitDetails.counterRetailPrice - this.form.profitDetails.totalCost;
    this.form.profitDetails.profitPerSet = this.form.profitDetails.profitPerTire * 4;
  }

  private validateTire(): void {
    this.isRegularRadioButtonDisabled = false;
    this.isStuddedRadioButtonDisabled = false;

    if (this.form.tire.regularPrice == 0) {
      this.isStudded = 1;
      this.isRegularRadioButtonDisabled = true;
    }
    else if (this.form.tire.studdedPrice == 0) {
      this.isStudded = 0
      this.isStuddedRadioButtonDisabled = true;
    }
  }
}
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    isAuthorized: boolean;

    constructor() {
        this.isAuthorized = false;
    }

    validateAccess(password: string): boolean {
        this.isAuthorized = password == "XTRM1c3";
        return this.isAuthorized;
    }
}

<div *ngIf="isAuthorized" class="card">

  <div class="card-header">
    <h5 class="card-title text-center">{{ "Home.Title" | translate }}</h5>
  </div>

  <div class="card-body container">
    <form>

      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="clientNameInput" minlength="1" [(ngModel)]="form.clientName"
          [ngModelOptions]="{standalone: true}" required>
        <label for="clientNameInput">{{ "Home.ClientNameLabel" | translate }}</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" id="representativeNameInput" minlength="1"
          [(ngModel)]="form.reprensentativeName" [ngModelOptions]="{standalone: true}" required>
        <label for="representativeNameInput">{{ "Home.RepresentativeNameLabel" | translate }}</label>
      </div>

      <div *ngIf="!isValid" class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>{{ "Home.FormValidationMessage" | translate }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

      <div style="margin-top:25px;">
        <p class="text-center">{{ "Home.DiscountsLabel" | translate }}</p>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-check ">
              <input class="form-check-input" type="checkbox" value="" id="bookingDiscountCheckbox"
                [(ngModel)]="form.discountDetails.isBookingDiscount" [ngModelOptions]="{standalone: true}"
                [disabled]="form.discountDetails.isAnnualIncentiveDiscount">
              <label class="form-check-label" for="bookingDiscountCheckbox">
                {{ "Home.BookingDiscountLabel" | translate }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="annualIncentiveDiscpimtCheckbox"
                [(ngModel)]="form.discountDetails.isAnnualIncentiveDiscount" [ngModelOptions]="{standalone: true}"
                [disabled]="form.discountDetails.isBookingDiscount">
              <label class="form-check-label" for="annualIncentiveDiscpimtCheckbox">
                {{ "Home.AnnualIncentiveLabel" | translate }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="loyaltyDollarDiscountCheckbox"
                [(ngModel)]="form.discountDetails.isLoyaltyDiscountDollar" [ngModelOptions]="{standalone: true}"
                [disabled]="form.discountDetails.isLoyaltyDiscountPercentage">
              <label class="form-check-label" for="loyaltyDollarDiscountCheckbox">
                {{ "Home.LoyaltyProgramLabel" | translate }} ($)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="loyaltyPercentageDiscountCheckbox"
                [(ngModel)]="form.discountDetails.isLoyaltyDiscountPercentage" [ngModelOptions]="{standalone: true}"
                [disabled]="form.discountDetails.isLoyaltyDiscountDollar">
              <label class="form-check-label" for="loyaltyPercentageDiscountCheckbox">
                {{ "Home.LoyaltyProgramLabel" | translate }} (%)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="contigoldDiscountCheckbox"
                [(ngModel)]="form.discountDetails.isContigoldDiscount" [ngModelOptions]="{standalone: true}"
                (change)="contigoldDiscountChanged()">
              <label class="form-check-label" for="contigoldDiscountCheckbox">
                {{ "Home.ContigoldDiscountLabel" | translate }}
              </label>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>

    </form>
  </div>

  <div class="card-footer">
    <div class="badge bg-secondary text-wrap">
      <p>{{ "Home.TermsAndConditions" | translate }}</p>
    </div>

    <div class="button-continue">
      <button type="button" class="btn btn-primary float-end" (click)="validateForm()">
        {{ "Home.ButtonLabel" | translate }}
      </button>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service'

@Component({
    selector: 'login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    isAuthorized: boolean;
    isValid: boolean;
    appPassword: string;

    constructor(private router: Router,
        private loginService: LoginService) {
        this.isAuthorized = false;
        this.isValid = true;
        this.appPassword = '';
    }

    ngOnInit() { }

    public validatePassword(): void {
        this.isAuthorized = this.loginService.validateAccess(this.appPassword);

        if (this.isAuthorized) {
            this.isValid = true;
            this.router.navigate([`/home`]);
        } else {
            this.isValid = false;
        }
    }
}
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FormService } from '../../services/form.service'
import { Form } from '../../models/Form';

@Component({
    selector: 'result-card-component',
    templateUrl: './result.card.component.html',
    styleUrls: ['./result.card.component.css']
})
export class ResultCardComponent implements OnInit {
    form: Form;

    constructor(private translateService: TranslateService,
        private formService: FormService) {
        this.form = this.formService.form;
    }

    ngOnInit() { }

    public getTireName(): string {
        var tireSize = this.form.tire.size;
        if (this.form.tire.isStudded == true) {
            if (this.currentLanguageIsFrench()) {
                tireSize = tireSize + ' clouté';
            } else {
                tireSize = tireSize + ' studded';
            }
        }

        return tireSize;
    }

    public currentLanguageIsFrench(): boolean {
        return this.translateService.currentLang == 'FR';
    }
}
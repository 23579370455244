export interface TireInterface {
    id: number,
    size: string,
    isStudded: boolean,
    regularPrice: number,
    studdedPrice: number,
}

export class Tire implements TireInterface {
    id: number;
    size: string;
    isStudded: boolean;
    regularPrice: number;
    studdedPrice: number;

    constructor(id: number, size: string, regularPrice: number, studdedPrice: number) {
        this.id = id;
        this.size = size;
        this.isStudded = false;
        this.regularPrice = regularPrice;
        this.studdedPrice = studdedPrice;
    }
}
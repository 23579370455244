<div class="card print">
    <div class="card-header">
        <h5 class="card-title text-center">{{ "Results.Title" | translate }}</h5>
    </div>

    <div class="card-body container">

        <fieldset class="form-group mx-2">
            <div class="row">
                <legend class="col-form-label col-sm-4 col-print-4 pt-0">Date</legend>
                <div class="col-sm-8 col-print-8">
                    <p *ngIf="currentLanguageIsFrench()">
                        {{ form.dateTime | date : 'mediumDate':'EDT':'fr-CA' }}</p>
                    <p *ngIf="!currentLanguageIsFrench()">
                        {{ form.dateTime | date: 'mediumDate':'EDT' }}</p>
                </div>
            </div>
        </fieldset>

        <fieldset class="form-group mx-2">
            <div class="row">
                <legend class="col-form-label col-sm-4 col-print-4 pt-0">
                    {{ "Results.ClientNameLabel" | translate }}
                </legend>
                <div class="col-sm-8 col-print-8">
                    <p>{{ form.clientName }}</p>
                </div>
            </div>
        </fieldset>

        <fieldset class="form-group mx-2">
            <div class="row">
                <legend class="col-form-label col-sm-4 col-print-4 pt-0">
                    {{ "Results.ReprensentativeNameLabel" | translate }}
                </legend>
                <div class="col-sm-8 col-print-8">
                    <p>{{ form.reprensentativeName }}</p>
                </div>
            </div>
        </fieldset>

        <fieldset class="form-group mx-2">
            <div class="row">
                <legend class="col-form-label col-sm-4 col-print-4 pt-0">{{ "Results.TireLabel" | translate }}</legend>
                <div class="col-sm-8 col-print-8">
                    <p>{{ getTireName() }} </p>
                </div>
            </div>
        </fieldset>

        <div class="row">
            <div class="col">
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="row">{{ "Results.MsrpLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.msrp | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.msrp | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{{ "Results.CrpLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.counterRetailPrice |
                                currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.counterRetailPrice | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6 class="text-center">{{ "Results.CostLabel" | translate }}</h6>
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="row">{{ "Results.CostPriceLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.costPrice | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.costPrice | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isBookingDiscount">
                            <th scope="row">{{ "Results.BookingDiscountLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.bookingDiscount | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.bookingDiscount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isBookingDiscount">
                            <th scope="row">{{ "Results.EarlyDeliveryDiscountLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.earlyDeliveryDiscount | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.earlyDeliveryDiscount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isAnnualIncentiveDiscount">
                            <th scope="row">{{ "Results.AnnualIncentiveLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.anunualIncentive | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA'
                                }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.anunualIncentive | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isContigoldDiscount"
                            style="border-top-color: black; border-top-width: 2px;">
                            <th scope="row">{{ "Results.ContigoldDiscountLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.contigoldDiscount | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA'
                                }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.contigoldDiscount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isContigoldDiscount">
                            <th scope="row">{{ "Results.AcceleratorDiscountLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.acceletorDiscount | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA'
                                }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.acceletorDiscount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isContigoldDiscount"
                            style="border-bottom-color: black; border-bottom-width: 2px;">
                            <th scope="row">{{"Results.ContigoldVolumeDiscountLabel" | translate}}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.contigoldVolumeDiscount |
                                currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.contigoldVolumeDiscount | currency:'CAD':'symbol-narrow':'1.2-2'
                                }}
                            </td>
                        </tr>
                        <tr *ngIf="form.discountDetails.isLoyaltyDiscountDollar || form.discountDetails.isLoyaltyDiscountPercentage">
                            <th scope="row">{{ "Results.LoyaltyProgramDiscountLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.dtTireVolumeDiscount |
                                currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.dtTireVolumeDiscount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr style="border-top-color: black; border-top-width: 5px;">
                            <th scope="row">{{ "Results.EndCostLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.totalCost | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.totalCost | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6 class="text-center">Profit</h6>
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="row">{{ "Results.ProfitPerTireLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.profitPerTire | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.profitPerTire | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{{ "Results.ProfitPerSetLabel" | translate }}</th>
                            <td *ngIf="currentLanguageIsFrench()">
                                {{ form.profitDetails.profitPerSet | currency:'CAD':'symbol-narrow':'1.2-2':'fr-CA' }}
                            </td>
                            <td *ngIf="!currentLanguageIsFrench()">
                                {{ form.profitDetails.profitPerSet | currency:'CAD':'symbol-narrow':'1.2-2' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
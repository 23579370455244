export interface DiscountDetailsInterface {
    isBookingDiscount: boolean,
    isContigoldDiscount: boolean,
    isLoyaltyDiscountPercentage: boolean,
    isLoyaltyDiscountDollar: boolean,
    isAnnualIncentiveDiscount: boolean,
}

export class DiscountDetails implements DiscountDetailsInterface {
    isBookingDiscount: boolean;
    isContigoldDiscount: boolean;
    isLoyaltyDiscountPercentage: boolean;
    isLoyaltyDiscountDollar: boolean;
    isAnnualIncentiveDiscount: boolean;

    constructor() {
        this.isBookingDiscount = false;
        this.isContigoldDiscount = false;
        this.isLoyaltyDiscountPercentage = false;
        this.isLoyaltyDiscountDollar = false;
        this.isAnnualIncentiveDiscount = false;
    }
}
<nav class="navbar sticky-top navbar-dark bg-dark">
    <div class="container-fluid">
        <a class="navbar-brand" href="home">
            <img class="navbar-brand" width="160" alt="IceContact Logo" src="../../../assets/IceContact-logo.jpg" />
        </a>

        <div class="d-flex">
            <select #langSelect class="d-flex form-select lang-select" (change)="translate.use(langSelect.value)">
                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                    [selected]="lang === translate.currentLang">{{ lang }}</option>
            </select>

            <img width="150" alt="DtTire Logo" src="../../../assets/DT-Tire-logo.png" />
        </div>
    </div>
</nav>
import { DiscountDetails } from "./DiscountDetails"
import { ProfitDetails } from "./ProfitDetails";
import { Tire } from "./Tire";

export interface FormInterface {
    clientName: string,
    reprensentativeName: string,
    dateTime: Date,
    tire: Tire,
    discountDetails: DiscountDetails,
    profitDetails: ProfitDetails
}

export class Form implements FormInterface {
    clientName: string;
    reprensentativeName: string;
    dateTime: Date;
    tire: Tire;
    discountDetails: DiscountDetails;
    profitDetails: ProfitDetails;

    constructor() {
        this.clientName = '';
        this.reprensentativeName = '';
        this.dateTime = new Date();
        this.tire = new Tire(-1, '', 0, 0);
        this.discountDetails = new DiscountDetails();
        this.profitDetails = new ProfitDetails();
    }
}
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { registerLocaleData, DatePipe, CurrencyPipe} from '@angular/common';
registerLocaleData(localeFr, 'fr-CA', localeFrExtra);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { ResultCardComponent } from './components/result.card/result.card.component';
import { FormComponent } from './routes/form/form.component';
import { HomeComponent } from './routes/home/home.component';
import { LoginComponent } from './routes/login/login.component'

import { FormService } from './services/form.service';
import { TireService } from './services/tire.service';
import { LoginService } from './services/login.service'

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ResultCardComponent,
    HomeComponent,
    FormComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    FormService,
    TireService,
    LoginService,
    TranslateModule,
    CurrencyPipe,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'en-CA' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div *ngIf="isAuthorized" class="card">

    <div class="card-header">
        <h5 class="card-title text-center">{{ "Form.Title" | translate }}</h5>
    </div>

    <div class="card-body container">
        <form>

            <!--Size-->
            <fieldset class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.TireSizeLabel" | translate }}</legend>
                    <div class="col-sm-6">
                        <select class="form-select" [(ngModel)]="selectedTire" [ngModelOptions]="{standalone: true}"
                            (change)="tireSizeChanged()">
                            <option *ngFor="let t of tires" [ngValue]="t">{{ t.size }}</option>
                        </select>
                    </div>
                </div>
            </fieldset>

            <!-- Type -->
            <fieldset class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.TireTypeLabel" | translate }}</legend>
                    <div class="col-sm-6" style="width: auto;">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="tireType" id="regularTire" value="0"
                                [(ngModel)]="isStudded" [ngModelOptions]="{standalone: true}"
                                (change)="tireSizeChanged()" [disabled]="isRegularRadioButtonDisabled">
                            <label class="form-check-label" for="regularTire">
                                {{ "Form.RegularRadioButtonLabel" | translate }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="tireType" id="studdedTire" value="1"
                                [(ngModel)]="isStudded" [ngModelOptions]="{standalone: true}"
                                (change)="tireSizeChanged()" [disabled]="isStuddedRadioButtonDisabled">
                            <label class="form-check-label" for="studdedTire">
                                {{ "Form.StuddedRadioButtonLabel" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </fieldset>

            <!--Counter retail price-->
            <fieldset class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.CrpLabel" | translate }}</legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <input class="form-control" type="number" min="0" max="100" [placeholder]="0"
                            [(ngModel)]="counterRetailPriceValue" [ngModelOptions]="{standalone: true}"
                            (change)="counterRetailPriceChanged()" />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </fieldset>

            <!-- Cost -->
            <fieldset class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.CostLabel" | translate }}</legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <input class="form-control" type="number" min="30" max="50" [placeholder]="0"
                            [(ngModel)]="costValue" [ngModelOptions]="{standalone: true}" 
                            (change)="costPriceChanged()" />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </fieldset>

            <!-- Xtrm booking -->
            <fieldset *ngIf="form.discountDetails.isBookingDiscount" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.BookingDiscountLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <select class="form-select" [(ngModel)]="bookingDiscountValue"
                            [ngModelOptions]="{standalone: true}" (change)="bookingDiscountChanged()">
                            <option value="0">{{ "Form.BookingDiscountOption0Label" | translate }}</option>
                            <option value="1">{{ "Form.BookingDiscountOption1Label" | translate }}</option>
                            <option value="2">{{ "Form.BookingDiscountOption2Label" | translate }}</option>
                            <option value="3">{{ "Form.BookingDiscountOption3Label" | translate }}</option>
                            <option value="4">{{ "Form.BookingDiscountOption4Label" | translate }}</option>
                            <option value="6">{{ "Form.BookingDiscountOption5Label" | translate }}</option>
                            <option value="7">{{ "Form.BookingDiscountOption6Label" | translate }}</option>
                        </select>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </fieldset>

            <!-- Xtrm Early delivery incentive -->
            <fieldset *ngIf="form.discountDetails.isBookingDiscount" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.EarlyDeliveryDiscountLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <select class="form-select" [(ngModel)]="earlyDeliveryDiscountValue"
                            [ngModelOptions]="{standalone: true}" (change)="earlyDeliveryDiscountChanged()">
                            <option value="0">{{ "Form.EarlyDeliveryDiscountOption0Label" | translate }}</option>
                            <option value="10">{{ "Form.EarlyDeliveryDiscountOption1Label" | translate }}</option>
                            <option value="8">{{ "Form.EarlyDeliveryDiscountOption2Label" | translate }}</option>
                            <option value="5">{{ "Form.EarlyDeliveryDiscountOption3Label" | translate }}</option>
                        </select>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </fieldset>

            <!-- Xtrm Earn as you go Incentive -->
            <fieldset *ngIf="form.discountDetails.isAnnualIncentiveDiscount" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.AnnualIncentiveLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <select class="form-select" [(ngModel)]="annualIncentiveValue"
                            [ngModelOptions]="{standalone: true}" (change)="annualIncentiveChanged()">
                            <option value="0">{{ "Form.EarnAsYouGoIncentiveOption0Label" | translate }}</option>
                            <option value="3">{{ "Form.EarnAsYouGoIncentiveOption1Label" | translate }}</option>
                            <option value="4">{{ "Form.EarnAsYouGoIncentiveOption2Label" | translate }}</option>
                            <option value="5">{{ "Form.EarnAsYouGoIncentiveOption3Label" | translate }}</option>
                            <option value="6">{{ "Form.EarnAsYouGoIncentiveOption4Label" | translate }}</option>
                            <option value="7">{{ "Form.EarnAsYouGoIncentiveOption5Label" | translate }}</option>
                            <option value="8">{{ "Form.EarnAsYouGoIncentiveOption6Label" | translate }}</option>
                            <option value="9">{{ "Form.EarnAsYouGoIncentiveOption7Label" | translate }}</option>
                            <option value="10">{{ "Form.EarnAsYouGoIncentiveOption8Label" | translate }}</option>
                            <option value="11">{{ "Form.EarnAsYouGoIncentiveOption9Label" | translate }}</option>
                            <option value="12">{{ "Form.EarnAsYouGoIncentiveOption10Label" | translate }}</option>
                        </select>
                        <span class="input-group-text">$</span>
                    </div>
                </div>
            </fieldset>

            <!-- Contigold volume bonus -->
            <fieldset *ngIf="form.discountDetails.isContigoldDiscount" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.ContigoldDiscountLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <select class="form-select" [(ngModel)]="contigoldVolumeBonusValue"
                            [ngModelOptions]="{standalone: true}" (change)="contigoldVolumeBonusChanged()">
                            <option value="0">0</option>
                            <option value="3.50">3.50 (500 - 749 un.)</option>
                            <option value="4.50">4.50 (750 - 999 un.)</option>
                            <option value="5.00">5.00 (1000 - 2499 un.)</option>
                            <option value="5.50">5.50 (2500+ un.)</option>
                        </select>
                        <span class="input-group-text">$</span>
                    </div>
                </div>
            </fieldset>

            <!-- DT Volume bonus ($)-->
            <fieldset *ngIf="form.discountDetails.isLoyaltyDiscountDollar" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.LoyaltyProgramLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <select class="form-select" [(ngModel)]="dtTireVolumeBonusDollarValue"
                            [ngModelOptions]="{standalone: true}" (change)="dtTireVolumeBonusDollarChanged()">
                            <option value="0">0</option>
                            <option value="3.00">3.00 (300 - 499 un.)</option>
                            <option value="4.00">4.00 (500 - 999 un.)</option>
                            <option value="5.00">5.00 (1000 - 1499 un.)</option>
                            <option value="6.00">6.00 (1500 - 2499 un.)</option>
                            <option value="7.00">7.00 (2500 - 7499 un.)</option>
                            <option value="8.00">8.00 (7500 - 9999 un.)</option>
                            <option value="9.00">9.00 (10000+ un.)</option>
                        </select>
                        <span class="input-group-text">$</span>
                    </div>
                </div>
            </fieldset>

            <!-- DT Volume bonus (%)-->
            <fieldset *ngIf="form.discountDetails.isLoyaltyDiscountPercentage" class="form-group mx-2">
                <div class="row">
                    <legend class="col-form-label col-sm-6 pt-0">{{ "Form.LoyaltyProgramLabel" | translate }}
                    </legend>
                    <div class="col-sm-6 input-group" style="width: auto;">
                        <input class="form-control" type="number" min="0" max="6" step="0.1" [placeholder]="0"
                            [(ngModel)]="dtTireVolumeBonusPercentageValue" [ngModelOptions]="{standalone: true}"
                            (change)="dtTireVolumeBonusPercentageChanged()" />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>

<div *ngIf="isAuthorized">
    <result-card-component></result-card-component>
</div>
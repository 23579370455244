import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { LoginService } from '../../services/login.service';
import { FormService } from '../../services/form.service'
import { Form } from '../../models/Form';

@Component({
  selector: 'home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  form: Form;
  isValid: boolean;
  isAuthorized: boolean;

  constructor(private router: Router,
    private formService: FormService,
    private loginService: LoginService) {
    this.form = new Form();
    this.isValid = true;
    this.isAuthorized = this.loginService.isAuthorized;
    //this.isAuthorized = true;
  }

  ngOnInit() {
    if (this.isAuthorized == false) {
      this.router.navigate([`/login`]);
    }
  }

  public validateForm() {
    if (this.form.clientName.length == 0 || this.form.reprensentativeName.length == 0) {
      this.isValid = false;
    }
    else {
      this.isValid = true;
      this.formService.form = this.form;

      this.router.navigate([`/form`]);
    }
  }

  public bookingDiscountChanged() {
    if (this.form.discountDetails.isBookingDiscount == true) {
      this.form.profitDetails.bookingDiscount
    }
  }

  public contigoldDiscountChanged() {
    if (this.form.discountDetails.isContigoldDiscount == true) {
      this.form.profitDetails.contigoldDiscount = 4;
      this.form.profitDetails.acceletorDiscount = 12;
    } else {
      this.form.profitDetails.contigoldDiscount = 0;
      this.form.profitDetails.acceletorDiscount = 0;
    }
  }
}
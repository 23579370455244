import { Injectable } from '@angular/core';
import { Tire } from '../models/Tire';

@Injectable({
    providedIn: 'root'
})
export class TireService {
    tires: Tire[];

    constructor() {
        this.tires = new Array<Tire>(
            new Tire(0,  '', 0, 0),

            new Tire(1,  '175/65R15', 217.00, 231.00),

            new Tire(2,  '185/60R15', 224.00, 241.00),
            new Tire(3,  '185/65R15', 220.00, 236.00),

            new Tire(4,  '195/65R15', 228.00, 245.00),

            new Tire(5,  '205/50R17', 327.00, 341.00),
            new Tire(6,  '205/55R16', 289.00, 309.00),
            new Tire(7,  '205/55R17', 336.00, 360.00),
            new Tire(8,  '205/60R16', 257.00, 275.00),
            new Tire(9,  '205/65R15', 244.00, 260.00),
            new Tire(10, '205/65R16', 268.00, 286.00),

            new Tire(11, '215/45R17', 375.00, 399.00),
            new Tire(12, '215/50R17', 367.00, 390.00),
            new Tire(13, '215/55R16', 317.00, 333.00),
            new Tire(14, '215/55R17', 338.00, 357.00),
            new Tire(15, '215/55R18', 385.00, 401.00),
            new Tire(16, '215/60R16', 286.00, 303.00),
            new Tire(17, '215/60R17', 319.00, 326.00),
            new Tire(18, '215/65R16', 285.00, 303.00),
            new Tire(19, '215/65R17', 301.00, 324.00),
            new Tire(20, '215/70R16', 321.00, 338.00),

            new Tire(21, '225/40R18', 443.00, 466.00),
            new Tire(22, '225/45R17', 392.00, 410.00),
            new Tire(23, '225/45R18', 411.00, 428.00),
            new Tire(24, '225/45R19', 487.00, 521.00),
            new Tire(25, '225/50R17', 388.00, 407.00),
            new Tire(26, '225/50R18', 423.00, 445.00),
            new Tire(27, '225/55R17', 346.00, 363.00),
            new Tire(28, '225/55R18', 378.00, 397.00),
            new Tire(29, '225/55R19', 423.00, 440.00),
            new Tire(30, '225/60R17', 324.00, 344.00),
            new Tire(31, '225/60R18', 375.00, 399.00),
            new Tire(32, '225/65R16', 292.00, 316.00),
            new Tire(33, '225/65R17', 307.00, 327.00),

            new Tire(34, '235/35R19', 573.00, 602.00),
            new Tire(35, '235/40R18', 468.00, 496.00),
            new Tire(36, '235/40R19', 539.00, 569.00),
            new Tire(37, '235/45R17', 396.00, 416.00),
            new Tire(38, '235/45R18', 445.00, 466.00),
            new Tire(39, '235/45R19', 488.00, 512.00),
            new Tire(40, '235/50R18', 425.00, 449.00),
            new Tire(41, '235/50R19', 465.00, 492.00),
            new Tire(42, '235/55R17', 362.00, 382.00),
            new Tire(43, '235/55R18', 401.00, 420.00),
            new Tire(44, '235/55R19', 430.00, 451.00),
            new Tire(45, '235/55R20', 449.00, 471.00),
            new Tire(46, '235/60R17', 345.00, 373.00),
            new Tire(47, '235/60R18', 413.00, 432.00),
            new Tire(48, '235/65R16', 303.00, 322.00),
            new Tire(49, '235/65R17', 357.00, 375.00),
            new Tire(50, '235/65R18', 413.00, 435.00),

            new Tire(51, '245/40R18', 482.00, 502.00),
            new Tire(52, '245/40R19', 537.00, 563.00),
            new Tire(53, '245/45R18', 449.00, 471.00),
            new Tire(54, '245/45R19', 491.00, 519.00),
            new Tire(55, '245/45R20', 541.00, 581.00),
            new Tire(56, '245/50R20', 475.00, 498.00),
            new Tire(57, '245/55R19', 474.00, 500.00),
            new Tire(58, '245/60R18', 414.00, 433.00),
            new Tire(59, '245/65R17', 380.00, 398.00),

            new Tire(60, '255/35R19', 627.00, 665.00),
            new Tire(61, '255/40R19', 559.00, 587.00),
            new Tire(62, '255/40R20', 661.00, 713.00),
            new Tire(63, '255/40R21', 728.00, 771.00),
            new Tire(64, '255/45R19', 517.00, 543.00),
            new Tire(65, '255/45R20', 556.00, 592.00),
            new Tire(66, '255/50R19', 458.00, 479.00),
            new Tire(67, '255/50R20', 509.00, 530.00),
            new Tire(68, '255/55R18', 451.00, 473.00),
            new Tire(69, '255/55R19', 461.00, 481.00),
            new Tire(70, '255/55R20', 483.00, 507.00),
            new Tire(71, '255/60R18', 443.00, 472.00),

            new Tire(72, '265/40R22', 785.00, 833.00),
            new Tire(73, '265/45R20', 573.00, 607.00),
            new Tire(74, '265/45R21', 757.00, 793.00),
            new Tire(75, '265/50R20', 524.00, 543.00),
            new Tire(76, '265/60R18', 445.00, 466.00),
            new Tire(77, '265/65R17', 417.00, 439.00),
            new Tire(79, '265/65R18', 462.00, 480.00),
            new Tire(79, '265/70R17', 393.00, 410.00),

            new Tire(80, '275/40R20', 581.00, 611.00),
            new Tire(81, '275/40R21', 778.00, 822.00),
            new Tire(82, '275/40R22', 780.00, 821.00),
            new Tire(83, '275/45R20', 555.00, 581.00),
            new Tire(84, '275/45R21', 719.00, 759.00),
            new Tire(85, '275/50R22', 616.00, 683.00),
            new Tire(86, '275/55R20', 495.00, 518.00),
            new Tire(87, '275/60R20', 566.00, 591.00),
            new Tire(88, '275/65R18', 484.00, 509.00),

            new Tire(89, '285/40R21', 807.00, 853.00),
            new Tire(90, '285/45R22', 670.00, 702.00),

            new Tire(91, '295/35R21', 739.00, 777.00),
            new Tire(92, '295/40R20', 679.00, 731.00),
            new Tire(93, '295/40R21', 823.00, 872.00)
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  ngOnInit() { }

  constructor(public translate: TranslateService) {
    translate.addLangs(['EN', 'FR']);
    translate.setDefaultLang('EN');

    const browserLang = translate.getBrowserLang();
    if (browserLang) {
      translate.use(browserLang.match(/EN|FR/) ? browserLang : 'EN');
    }
  }
}